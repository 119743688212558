function addAnimation(e) {
  for (var t = 0; t < e.length; t++) e[t] && e[t].classList && e[t].classList.add("fade-in");
  initAnimation(), animate();
}
function initAnimation() {
  (elements = document.querySelectorAll(".fade-in")), (windowHeight = window.innerHeight);
}
function animate() {
  if (elements)
      for (var e = 0; e < elements.length; e++) {
          var t = elements[e];
          elements[e].getBoundingClientRect().top - windowHeight <= 0 && t.classList.add("animate");
      }
}
function _classCallCheck(e, t) {
  if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
}

function _defineProperties(e, t) {
  for (var n = 0; n < t.length; n++) {
      var r = t[n];
      (r.enumerable = r.enumerable || !1), (r.configurable = !0), "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
  }
}

function _createClass(e, t, n) {
  return t && _defineProperties(e.prototype, t), n && _defineProperties(e, n), e;
}

function debounce(e, t, n) {
  var r;
  return function () {
      var i = this,
          o = arguments,
          a = function () {
              (r = null), n || e.apply(i, o);
          },
          l = n && !r;
      clearTimeout(r), (r = setTimeout(a, t)), l && e.apply(i, o);
  };
}

(function () {
  var e = document.getElementsByTagName("footer"),
    t = document.getElementsByClassName("links-category"),
    n =
      (document.getElementsByClassName("links-list"),
      document.getElementsByClassName("links-container")),
    r = document.getElementsByClassName("ftr-top")[0],
    i = debounce(function () {
      if (window.innerWidth > 1023) {
        r.style.flexFlow = "column wrap";
        for (var e = [], i = 0; i < n.length; i++) e.push(n[i].offsetHeight);
        (tallestContainer = Math.max.apply(null, e)),
          (r.style.height = tallestContainer + 80 + "px"),
          (canvas.style.height = tallestContainer + 80 + "px");
      } else (r.style.height = "auto"), (r.style.flexFlow = "row nowrap");
      for (i = 0; i < t.length; i++)
        window.innerWidth > 1023
          ? t[i].removeAttribute("tabindex")
          : t[i].setAttribute("tabindex", 0);
    }, 250);
  0 != e.length &&
    (window.addEventListener("resize", function () {
      i();
    }),
    window.addEventListener("load", i));
})();

(function () {
  var e,
    t = document.getElementById("canvas");
  function n() {
    var e = window.devicePixelRatio,
      n = t.getContext("2d"),
      r = document.getElementsByClassName("ftr-top")[0];
    function i() {
      (e = window.devicePixelRatio),
        (t.width = t.style.width = "100vw"),
        (t.height = r.offsetHeight);
      var i = t.getBoundingClientRect();
      (t.width = i.width * devicePixelRatio),
        (t.height = i.height * devicePixelRatio),
        n.scale(devicePixelRatio, devicePixelRatio),
        (t.style.width = i.width + "px"),
        (t.style.height = i.height + "px");
    }
    (t.width = t.offsetWidth), (t.height = r.offsetHeight);
    var o = { x: null, y: null, radius: 57.4 };
    r.addEventListener(
      "mousemove",
      function (e) {
        o = (function (e, t) {
          var n = e.getBoundingClientRect();
          return {
            x: t.clientX - n.left,
            y: t.clientY - n.top,
            radius: 57.4,
          };
        })(t, e);
      },
      !1
    );
    var a = (function () {
      function e(t, n, r, i, o, a) {
        _classCallCheck(this, e),
          (this.x = t),
          (this.y = n),
          (this.directionX = r),
          (this.directionY = i),
          (this.size = o),
          (this.color = a);
      }
      return (
        _createClass(e, [
          {
            key: "draw",
            value: function () {
              n.beginPath(),
                n.arc(this.x, this.y, this.size, 0, 2 * Math.PI, !1),
                (n.fillStyle = this.color),
                n.fill();
            },
          },
          {
            key: "update",
            value: function () {
              (this.x > t.width || this.x < 0) &&
                (this.directionX = -this.directionX),
                (this.y > t.height || this.y < 0) &&
                  (this.directionY = -this.directionY);
              var e = o.x - this.x,
                n = o.y - this.y;
              Math.sqrt(e * e + n * n) < o.radius + this.size &&
                (o.x < this.x &&
                  this.x < t.width - 10 * this.size &&
                  (this.x += 5),
                o.x > this.x && this.x > 10 * this.size && (this.x -= 5),
                o.y < this.y &&
                  this.y < t.height - 10 * this.size &&
                  (this.y += 5),
                o.y > this.y && this.y > 10 * this.size && (this.y -= 5)),
                (this.x += this.directionX),
                (this.y += this.directionY),
                this.draw();
            },
          },
        ]),
        e
      );
    })();
    function l() {
      var n;
      (particlesArray = []),
        (n = 1 == e ? (t.height * t.width) / 2e3 : 183.552);
      for (var r = 0; r < n / 2; r++) {
        var i = 5 * Math.random() + 1,
          o = Math.random() * (innerWidth - 2 * i - 2 * i) + 2 * i,
          l = Math.random() * (innerHeight - 2 * i - 2 * i) + 2 * i,
          s = 1 * Math.random() - 0.5,
          c = 1 * Math.random() - 0.5,
          u = "#de8627";
        particlesArray.push(new a(o, l, s, c, i, u));
      }
      for (r = 0; r < n / 2; r++) {
        (i = 5 * Math.random() + 1),
          (o = Math.random() * (innerWidth - 2 * i - 2 * i) + 2 * i),
          (l = Math.random() * (innerHeight - 2 * i - 2 * i) + 2 * i),
          (s = 1 * Math.random() - 0.5),
          (c = 1 * Math.random() - 0.5),
          (u = "#d4e7f1");
        particlesArray.push(new a(o, l, s, c, i, u));
      }
    }
    var s = debounce(function () {
      window.innerWidth > 1023 && i(),
        (o.radius = 57.4),
        l(),
        (t.style.opacity = "1");
    }, 250);
    window.addEventListener("resize", function () {
      (t.style.opacity = "0"), s();
    }),
      window.addEventListener("mouseout", function () {
        (o.x = void 0), (o.x = void 0);
      }),
      i(),
      l(),
      (function e() {
        requestAnimationFrame(e), n.clearRect(0, 0, innerWidth, innerHeight);
        for (var r = 0; r < particlesArray.length; r++)
          particlesArray[r].update();
        !(function () {
          for (var e = 0.5, r = 0; r < particlesArray.length; r++)
            for (var i = r; i < particlesArray.length; i++) {
              var o =
                (particlesArray[r].x - particlesArray[i].x) *
                  (particlesArray[r].x - particlesArray[i].x) +
                (particlesArray[r].y - particlesArray[i].y) *
                  (particlesArray[r].y - particlesArray[i].y);
              o < (t.width / 2) * (t.height / 2) &&
                ((e = 0.5 - o / 2e4),
                (n.strokeStyle = "rgba(151,159,173," + e + ")"),
                (n.lineWidth = 1),
                n.beginPath(),
                n.moveTo(particlesArray[r].x, particlesArray[r].y),
                n.lineTo(particlesArray[i].x, particlesArray[i].y),
                n.stroke());
            }
        })();
      })();
  }
  ((e = navigator.userAgent), /MSIE|Trident/.test(e)) ||
    (t &&
      window.addEventListener("load", function () {
        setTimeout(function () {
          n();
        }, 250);
      }));
})();